import React, { useEffect, useState, useRef, createRef } from 'react'
import Pusher from 'pusher-js'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { optimizeImage } from '@sellpy/commons/lib/imageTools'

const MAX_LEN = 12
let highestProcessedItems = 0

const formatFloat = (num) => num.toFixed(2)
const formatThousands = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

const ItemsProcessed = ({ user }) => {
  const [items, setItems] = useState(
    Array.from({ length: MAX_LEN }).map(() => ({
      item: null,
      nodeRef: createRef(null),
    }))
  )
  const itemsRef = useRef(items)
  const [processedItems, setProcessedItems] = useState(formatThousands(11321))
  const [minutesPerItem, setMinutesPerItem] = useState(formatFloat(8.5232))

  let recentlyUsedPositions = new Array(8)

  useEffect(() => {
    if (!user) return

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: 'eu',
      authEndpoint: `${process.env.REACT_APP_PARSE_SERVER_URL}/pusher/auth`,
      auth: {
        params: {
          sessionToken: (user && user.sessionToken) || undefined,
          appId: process.env.REACT_APP_PUSHER_APP_ID,
        },
      },
    })
    const channel = pusher.subscribe('private-processed-items')
    channel.bind('pusher:subscription_error', (error) => {
      console.error(error)
      if (error.type === 'AuthError') {
        window.localStorage.removeItem('user')
        window.location.reload()
      }
    })
    channel.bind('item-packed', (item) => {
      let positionToInsert = Math.floor(Math.random() * MAX_LEN)
      while (recentlyUsedPositions.includes(positionToInsert)) {
        positionToInsert = Math.floor(Math.random() * MAX_LEN)
      }
      recentlyUsedPositions = [positionToInsert, ...recentlyUsedPositions.slice(0, 4)]

      const newItems = [...itemsRef.current]
      newItems[positionToInsert] = { ...newItems[positionToInsert], item }
      itemsRef.current = newItems
      setItems(newItems)
      if (item.processedItems && item.processedItems > highestProcessedItems) {
        highestProcessedItems = item.processedItems
        setProcessedItems(formatThousands(item.processedItems))
      }
      if (item.minutesPerItem && item.minutesPerItem !== minutesPerItem) setMinutesPerItem(formatFloat(item.minutesPerItem))
    })
  }, [user])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '15%' }}>
        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'flex-end' }}>
          <div style={{ display: 'flex' }}>
            <p style={{ margin: '7.5rem 0 0 7.5rem', padding: 0, fontSize: '5rem', fontWeight: 500 }}>PROCESSED ITEMS</p>
            <div className='pulsating-circle'></div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'flex-end' }}>
          <div style={{ margin: '7.5rem 7.5rem 0 0', padding: 0, fontSize: '3rem', wordSpacing: '-3px' }}>{`TODAY: ${processedItems}`}</div>
        </div>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', height: '70%', margin: '1em 0' }}>
        {items.map(({ item, nodeRef, e }) => (
          <SwitchTransition key={e}>
            <CSSTransition
              nodeRef={nodeRef}
              classNames='item'
              key={(item && item.objectId) || e}
              timeout={300}
              style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '30vh',
                padding: '10px',
              }}
            >
              <div ref={nodeRef}>
                {item && (
                  <div style={{ width: '100%', height: '100%' }}>
                    <img
                      src={optimizeImage(item.photoUrls[0], { width: 400 })}
                      style={{ height: '100%', width: '100%', borderRadius: '8px' }}
                    />
                  </div>
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <p style={{ margin: '0 0 5rem 7.5rem', fontSize: '3rem' }}>MIN/ITEM:</p>
        <div>
          <p
            style={{
              margin: '0 0 5rem 0',
              fontSize: '5rem',
              border: '4px solid',
              borderRadius: '7px',
              padding: '0.25rem 0.25rem',
              fontWeight: 500,
            }}
          >
            {minutesPerItem}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ItemsProcessed
