import React, { useState } from 'react'
import commons from '@sellpy/commons'
import { v4 as getUuidV4 } from 'uuid'
import { parseFetch } from './parseFetch.js'

// From https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
const hashFunction = async (text) => {
  const msgUint8 = new TextEncoder().encode(text)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8)
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

const logIn = async (username, password) => {
  const loginToken = await parseFetch('getLoginToken', { username, password })
  const hashResult = await commons.auth.generateValidLoginHash(hashFunction)({
    loginToken,
    username,
    password,
  })
  const sessionToken = await parseFetch('logInAdmin', {
    username,
    password,
    loginToken,
    ...hashResult,
  })
  const user = { sessionToken, installationId: getUuidV4() }
  window.localStorage.setItem('user', JSON.stringify(user))
  return user
}

export const Login = ({ setUser }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()
    setUser(await logIn(email, password))
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Email:
        <input type='text' value={email} onChange={(event) => setEmail(event.target.value)} />
      </label>
      <label>
        Password:
        <input type='password' value={password} onChange={(event) => setPassword(event.target.value)} />
      </label>
      <input type='submit' value='Submit' />
    </form>
  )
}
