import { v4 as uuidv4 } from 'uuid'

const INITIAL_INSTALLATION_ID = uuidv4()

const _fetch = async (cloudFunctionName, body) => {
  let user
  try {
    user = window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user'))
  } catch (error) {
    if (!(error instanceof SecurityError)) throw error
    user = { installationId: INITIAL_INSTALLATION_ID }
  }

  const url = new URL(`${process.env.REACT_APP_PARSE_SERVER_URL}/parse/functions/${cloudFunctionName}`)
  const response = await window.fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ...body,
      _InstallationId: (user && user.installationId) || INITIAL_INSTALLATION_ID,
      _ApplicationId: process.env.REACT_APP_PARSE_APPLICATION_ID,
      _JavaScriptKey: process.env.REACT_APP_PARSE_JAVASCRIPT_KEY,
      _SessionToken: (user && user.sessionToken) || undefined,
    }),
  })
  return response.json()
}

export const parseFetch = async (cloudFunctionName, body) => {
  const response = await _fetch(cloudFunctionName, body)
  if (response.error) {
    if (response.code && response.code === 209) {
      window.localStorage.removeItem('user')
      window.location.reload(true)
    }
    throw response
  }
  return response.result
}
