import React, { useEffect, useState } from 'react'
import { Login } from './Auth.jsx'
import ItemsProcessed from './ItemsProcessed.jsx'

function App() {
  useEffect(() => {
    const timeout = setTimeout(() => window.location.reload(), 1000 * 60 * 60)
    return () => clearTimeout(timeout)
  }, [])
  const [user, setUser] = useState(window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user')))
  return (
    <>
      {user ? (
        <>
          <ItemsProcessed user={user} />
        </>
      ) : (
        <Login setUser={setUser} />
      )}
    </>
  )
}

export default App
